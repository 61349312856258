
.column {
  width: 350px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

@media screen and (min-width: 631px) {
  .column {
    flex: 0 0 auto;
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }
}

.column-link {
  background: var(--brand-color--med);
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 15px;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background: var(--brand-color--faint);
  }

  &:focus {
    outline: 0;
  }

  &--transparent {
    @apply bg-transparent;
    color: var(--background-color);

    &:hover,
    &:focus,
    &:active {
      @apply text-gray-900 bg-transparent;
    }

    &.active {
      color: var(--brand-color);
    }
  }
}

.svg-icon.column-link__icon {
  display: inline-block;
  margin-right: 5px;
}

.column-header__setting-btn {
  &--link {
    text-decoration: none;
  }

  &:hover {
    @apply text-gray-400 underline;
  }
}

.empty-column-indicator,
.error-column {
  @apply bg-primary-50 dark:bg-gray-700 text-gray-900 dark:text-gray-300 text-center p-10 flex flex-1 items-center justify-center min-h-[160px] rounded-lg;

  @supports (display: grid) { // hack to fix Chrome <57
    contain: strict;
  }

  & > span {
    @apply max-w-[400px];
  }

  a {
    @apply text-primary-600 dark:text-primary-400 no-underline hover:underline;
  }
}

.error-column {
  flex-direction: column;

  .svg-icon {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
}
